import React from "react";

const ErrorDataLoading: React.FC = () => {
    return (
        <div className='flex items-center justify-center h-full w-full'>
            <h3>Что-то пошло не так...</h3>
        </div>

    )
}
export {ErrorDataLoading};